<template>
  <div class="login-container">
    <h2>Admin Login</h2>
    <form @submit.prevent="login">
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="email" class="form-control" id="email" v-model="email" />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="password"
        />
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
    </form>
  </div>
</template>
  
  <script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const email = ref("");
    const password = ref("");
    const router = useRouter();

    const login = async () => {
      try {
        await store.dispatch("login", {
          email: email.value,
          password: password.value,
        });
        watch(
          () => store.state.userProfile,
          (newVal) => {
            if (newVal) {
              router.push("/dashboard");
            }
          }
        );
      } catch (error) {}
    };

    return {
      email,
      password,
      login,
    };
  },
};
</script>
  
  <style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
  padding-top: 50px;
}
</style>
  