<template>
  <div class="canvas-container">
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>
  
  <script>
import { ref, onMounted, watch } from "vue";
import Chart from "chart.js/auto";

export default {
  name: "PostsByLangChart",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const chartCanvas = ref(null);
    let chartInstance = null;

    const createChart = () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
      chartInstance = new Chart(chartCanvas.value.getContext("2d"), {
        type: "doughnut",
        data: {
          labels: Object.keys(props.data),
          datasets: [
            {
              data: Object.values(props.data),
              backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#9966FF",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    };

    onMounted(() => {
      createChart();
    });

    watch(
      () => props.data,
      () => {
        createChart();
      }
    );

    return {
      chartCanvas,
    };
  },
};
</script>
  
  <style scoped>
.canvas-container {
  position: relative;
  height: 300px;
}
</style>
  