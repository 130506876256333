<template>
  <div class="manage-posts-container">
    <Sidebar />
    <div class="main-content">
      <Navbar />
      <div class="content-wrapper">
        <!-- Header Row -->
        <div class="header-row">
          <h2>Manage Posts</h2>
          <button class="btn btn-primary" @click="createPost">
            <i class="bi bi-plus-circle"></i> Create New Post
          </button>
        </div>

        <!-- Filter Pane -->
        <div class="filter-pane mb-3">
          <div class="row">
            <div class="col-md-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filter Title"
                v-model="filters.title"
                @input="applyFilters"
              />
            </div>
            <div class="col-md-2">
              <input
                type="text"
                class="form-control"
                placeholder="Filter Author"
                v-model="filters.author"
                @input="applyFilters"
              />
            </div>
            <div class="col-md-2">
              <select
                class="form-select"
                v-model="filters.lang"
                @change="applyFilters"
              >
                <option value="">All Languages</option>
                <option
                  v-for="lang in availableLangs"
                  :key="lang"
                  :value="lang"
                >
                  {{ lang }}
                </option>
              </select>
            </div>
            <div class="col-md-2">
              <input
                type="date"
                class="form-control"
                v-model="filters.createdAt"
                @change="applyFilters"
              />
            </div>
          </div>
        </div>

        <!-- Loading Spinner -->
        <LoadingSpinner v-if="isLoading" />

        <!-- Posts Table -->
        <table class="table" v-else>
          <thead>
            <tr>
              <th @click="sortBy('title')">
                Title
                <i :class="getSortIcon('title')"></i>
              </th>
              <th @click="sortBy('author')">
                Author
                <i :class="getSortIcon('author')"></i>
              </th>
              <th @click="sortBy('lang')">
                Lang
                <i :class="getSortIcon('lang')"></i>
              </th>
              <th @click="sortBy('createdAt')">
                Created At
                <i :class="getSortIcon('createdAt')"></i>
              </th>
              <th @click="sortBy('viewCount')">
                Views
                <i :class="getSortIcon('viewCount')"></i>
              </th>
              <th @click="sortBy('readCount')">
                Reads
                <i :class="getSortIcon('readCount')"></i>
              </th>
              <th @click="sortBy('commentCount')">
                Comments
                <i :class="getSortIcon('commentCount')"></i>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="post in filteredPosts" :key="post.id">
              <td>{{ post.title }}</td>
              <td>{{ post.author }}</td>
              <td>{{ post.lang }}</td>
              <td>{{ formatTimestamp(post.createdAt) }}</td>
              <td>{{ post.viewCount || 0 }}</td>
              <td>{{ post.readCount || 0 }}</td>
              <td>{{ post.commentCount || 0 }}</td>
              <td>
                <a
                  class="btn btn-info"
                  :href="generatePostUrl(post)"
                  target="_blank"
                >
                  <i class="bi bi-eye"></i> View
                </a>
                <span class="mx-1"></span>
                <button class="btn btn-success" @click="editPost(post.id)">
                  <i class="bi bi-pencil-square"></i> Edit
                </button>
                <span class="mx-1"></span>
                <button class="btn btn-danger" @click="confirmDelete(post)">
                  <i class="bi bi-trash"></i> Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Confirmation Dialog -->
        <ConfirmationDialog
          :visible="isDialogVisible"
          :title="'Delete Post'"
          :message="`Are you sure you want to delete the post titled '${postToDelete?.title}'?`"
          @confirm="deletePostConfirmed"
          @cancel="isDialogVisible = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "@/firebase/config";
import { useRouter } from "vue-router";

export default {
  components: {
    Navbar,
    Sidebar,
    LoadingSpinner,
    ConfirmationDialog,
  },
  setup() {
    const store = useStore();
    const posts = ref([]);
    const filteredPosts = ref([]);
    const isLoading = ref(true);
    const router = useRouter();
    const sortKey = ref("createdAt");
    const sortOrders = ref({
      title: 1,
      author: 1,
      lang: 1,
      createdAt: -1,
      viewCount: -1,
      readCount: -1,
      commentCount: -1,
    });
    const filters = ref({
      title: "",
      author: "",
      lang: "",
      createdAt: "",
    });
    const availableLangs = ref([]);

    // Confirmation Dialog State
    const isDialogVisible = ref(false);
    const postToDelete = ref(null);

    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, "Posts"));
      const fetchedPosts = [];
      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        const commentCount = await getCommentCount(doc.id);
        fetchedPosts.push({
          id: doc.id,
          ...data,
          commentCount,
        });
        if (!availableLangs.value.includes(data.lang)) {
          availableLangs.value.push(data.lang);
        }
      }
      posts.value = fetchedPosts;
      applyFilters();
      isLoading.value = false;
    };

    const getCommentCount = async (postId) => {
      const commentsRef = collection(db, "Posts", postId, "Comments");
      const commentsSnapshot = await getDocs(commentsRef);
      let count = commentsSnapshot.size;
      for (const commentDoc of commentsSnapshot.docs) {
        const repliesRef = collection(
          db,
          "Posts",
          postId,
          "Comments",
          commentDoc.id,
          "Replies"
        );
        const repliesSnapshot = await getDocs(repliesRef);
        count += repliesSnapshot.size;
      }
      return count;
    };

    const formatTimestamp = (timestamp) => {
      if (timestamp && timestamp.seconds) {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleString(); // Adjust to your desired format
      }
      return "N/A";
    };

    const createPost = () => {
      router.push({ name: "NewPost", params: {} });
    };

    const editPost = (postId) => {
      router.push({ name: "EditPost", params: { id: postId } });
    };

    const confirmDelete = (post) => {
      postToDelete.value = post;
      isDialogVisible.value = true;
    };

    const deletePostConfirmed = async () => {
      isDialogVisible.value = false;
      isLoading.value = true;
      try {
        const postId = postToDelete.value.id;
        const postRef = doc(db, "Posts", postId);

        // Step 1: Retrieve the post data
        const postSnapshot = await getDoc(postRef);
        if (postSnapshot.exists()) {
          const postData = postSnapshot.data();
          postData.deletedAt = new Date();

          // Step 2: Add the post data to the "DeletedPosts" collection
          const deletedPostRef = doc(db, "DeletedPosts", postId);
          await setDoc(deletedPostRef, postData);

          // Step 2a: Copy Comments Subcollection
          const commentsRef = collection(db, "Posts", postId, "Comments");
          const commentsSnapshot = await getDocs(commentsRef);

          for (const commentDoc of commentsSnapshot.docs) {
            const commentData = commentDoc.data();
            const deletedCommentRef = doc(
              db,
              "DeletedPosts",
              postId,
              "Comments",
              commentDoc.id
            );
            await setDoc(deletedCommentRef, commentData);

            // Copy Replies Subcollection
            const repliesRef = collection(
              db,
              "Posts",
              postId,
              "Comments",
              commentDoc.id,
              "Replies"
            );
            const repliesSnapshot = await getDocs(repliesRef);
            for (const replyDoc of repliesSnapshot.docs) {
              const replyData = replyDoc.data();
              const deletedReplyRef = doc(
                db,
                "DeletedPosts",
                postId,
                "Comments",
                commentDoc.id,
                "Replies",
                replyDoc.id
              );
              await setDoc(deletedReplyRef, replyData);
            }
          }

          // Step 3: Delete the post and its subcollections from the "Posts" collection
          // Delete subcollections first
          for (const commentDoc of commentsSnapshot.docs) {
            // Delete Replies Subcollection
            const repliesRef = collection(
              db,
              "Posts",
              postId,
              "Comments",
              commentDoc.id,
              "Replies"
            );
            const repliesSnapshot = await getDocs(repliesRef);
            for (const replyDoc of repliesSnapshot.docs) {
              await deleteDoc(
                doc(
                  db,
                  "Posts",
                  postId,
                  "Comments",
                  commentDoc.id,
                  "Replies",
                  replyDoc.id
                )
              );
            }
            // Delete the comment
            await deleteDoc(
              doc(db, "Posts", postId, "Comments", commentDoc.id)
            );
          }

          // Delete the post document
          await deleteDoc(postRef);

          // Remove the post from the local list
          posts.value = posts.value.filter((p) => p.id !== postId);
          applyFilters();
        } else {
          console.error("Post does not exist.");
          alert("Post does not exist.");
        }
      } catch (error) {
        console.error("Error deleting post:", error);
        alert("An error occurred while deleting the post. Please try again.");
      } finally {
        isLoading.value = false;
      }
    };

    const generatePostUrl = (post) => {
      const formattedTitle = encodeURIComponent(
        post.title.replace(/\s+/g, "%20")
      );
      return `https://thesyrianblog.com/post/${formattedTitle}/${post.id}`;
    };

    const sortBy = (key) => {
      sortOrders.value[key] = sortOrders.value[key] * -1;
      sortKey.value = key;
      applyFilters();
    };

    const getSortIcon = (key) => {
      if (sortKey.value === key) {
        return sortOrders.value[key] === 1
          ? "bi bi-sort-up"
          : "bi bi-sort-down";
      }
      return "bi bi-arrow-down-up";
    };

    const applyFilters = () => {
      let tempPosts = posts.value;

      // Apply Filters
      if (filters.value.title) {
        tempPosts = tempPosts.filter((post) =>
          post.title.toLowerCase().includes(filters.value.title.toLowerCase())
        );
      }
      if (filters.value.author) {
        tempPosts = tempPosts.filter((post) =>
          post.author.toLowerCase().includes(filters.value.author.toLowerCase())
        );
      }
      if (filters.value.lang) {
        tempPosts = tempPosts.filter(
          (post) => post.lang === filters.value.lang
        );
      }
      if (filters.value.createdAt) {
        tempPosts = tempPosts.filter((post) => {
          const postDate = new Date(post.createdAt.seconds * 1000)
            .toISOString()
            .split("T")[0];
          return postDate === filters.value.createdAt;
        });
      }

      // Apply Sorting
      tempPosts = tempPosts.sort((a, b) => {
        let result = 0;
        if (sortKey.value === "createdAt") {
          result = a[sortKey.value].seconds - b[sortKey.value].seconds;
        } else {
          result =
            a[sortKey.value] > b[sortKey.value]
              ? 1
              : a[sortKey.value] < b[sortKey.value]
              ? -1
              : 0;
        }
        return result * sortOrders.value[sortKey.value];
      });

      filteredPosts.value = tempPosts;
    };

    onMounted(() => {
      fetchPosts();
    });

    return {
      posts,
      filteredPosts,
      isLoading,
      createPost,
      editPost,
      isDialogVisible,
      postToDelete,
      confirmDelete,
      deletePostConfirmed,
      formatTimestamp,
      generatePostUrl,
      sortBy,
      getSortIcon,
      filters,
      applyFilters,
      availableLangs,
    };
  },
};
</script>

<style scoped>
.manage-posts-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  padding: 2rem;
  flex-grow: 1;
}

.header-row {
  display: flex;
  justify-content: space-between; /* Aligns the title and button on opposite ends */
  align-items: center;
  margin-bottom: 1rem;
}

.table {
  width: 100%;
}

.table th {
  cursor: pointer;
}

.filter-pane .form-control,
.filter-pane .form-select {
  width: 100%;
}
</style>
