<template>
  <div class="canvas-container">
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>
  
  <script>
import { ref, onMounted, watch } from "vue";
import Chart from "chart.js/auto";

export default {
  name: "MonthlyPostsChart",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const chartCanvas = ref(null);
    let chartInstance = null;

    const createChart = () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
      const sortedMonths = Object.keys(props.data).sort();
      const postCounts = sortedMonths.map((month) => props.data[month]);

      chartInstance = new Chart(chartCanvas.value.getContext("2d"), {
        type: "line",
        data: {
          labels: sortedMonths,
          datasets: [
            {
              label: "Posts",
              data: postCounts,
              fill: false,
              borderColor: "#36A2EB",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    };

    onMounted(() => {
      createChart();
    });

    watch(
      () => props.data,
      () => {
        createChart();
      }
    );

    return {
      chartCanvas,
    };
  },
};
</script>
  
  <style scoped>
.canvas-container {
  position: relative;
  height: 300px;
}
</style>
  