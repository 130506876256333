<template>
  <div
    class="d-flex justify-content-center align-items-center"
    :style="spinnerStyle"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    fullPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    spinnerStyle() {
      return this.fullPage
        ? { width: "100%", height: "100vh" }
        : { width: "100%", height: "100%" };
    },
  },
};
</script>
  
  <style scoped>
/* Optional: Add custom styles here */
</style>
  