<template>
  <div class="dashboard-container">
    <Sidebar />
    <div class="main-content">
      <Navbar />
      <div class="content-wrapper">
        <h2>Dashboard</h2>
        <!-- Loading Spinner -->
        <LoadingSpinner v-if="isLoading" fullPage />

        <!-- Dashboard Content -->
        <div v-else>
          <!-- Metrics Cards -->
          <div class="row mb-4">
            <div class="col-md-3" v-for="metric in metrics" :key="metric.title">
              <div class="card text-center">
                <div class="card-body">
                  <h5 class="card-title">{{ metric.title }}</h5>
                  <p class="card-text display-4">{{ metric.value }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Charts -->
          <div class="row">
            <!-- Posts by Language Chart -->
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Posts by Language</h5>
                  <PostsByLangChart :data="postsByLang" />
                </div>
              </div>
            </div>

            <!-- Monthly Posts Chart -->
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Monthly New Posts</h5>
                  <MonthlyPostsChart :data="monthlyPosts" />
                </div>
              </div>
            </div>
          </div>

          <!-- Top Posts Table -->
          <div class="row mt-4">
            <div class="col">
              <h5>Top Posts by Views</h5>
              <table class="table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Views</th>
                    <th>Reads</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="post in topPosts" :key="post.id">
                    <td>{{ post.title }}</td>
                    <td>{{ post.viewCount }}</td>
                    <td>{{ post.readCount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- User Engagement -->
          <div class="row mt-4">
            <div class="col-md-6">
              <h5>User Engagement</h5>
              <p>Total Comments: {{ totalComments }}</p>
              <p>Total Replies: {{ totalReplies }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PostsByLangChart from "@/components/PostsByLangChart.vue";
import MonthlyPostsChart from "@/components/MonthlyPostsChart.vue";
import { ref, onMounted } from "vue";
import { db } from "@/firebase/config";
import { collection, getDocs } from "firebase/firestore";

export default {
  components: {
    Navbar,
    Sidebar,
    LoadingSpinner,
    PostsByLangChart,
    MonthlyPostsChart,
  },
  setup() {
    const isLoading = ref(true);
    const metrics = ref([]);
    const topPosts = ref([]);
    const totalComments = ref(0);
    const totalReplies = ref(0);
    const postsByLang = ref({});
    const monthlyPosts = ref({});

    const fetchData = async () => {
      // Fetch Posts
      const postsSnapshot = await getDocs(collection(db, "Posts"));
      const posts = [];
      let totalViews = 0;
      let totalReads = 0;
      postsByLang.value = {};
      monthlyPosts.value = {};

      for (const doc of postsSnapshot.docs) {
        const data = doc.data();
        posts.push({ id: doc.id, ...data });
        totalViews += data.viewCount || 0;
        totalReads += data.readCount || 0;

        // Posts by Language
        const lang = data.lang || "Unknown";
        postsByLang.value[lang] = (postsByLang.value[lang] || 0) + 1;

        // Monthly Posts
        const createdAt = data.createdAt?.toDate();
        if (createdAt) {
          const month = `${createdAt.getFullYear()}-${(
            "0" +
            (createdAt.getMonth() + 1)
          ).slice(-2)}`;
          monthlyPosts.value[month] = (monthlyPosts.value[month] || 0) + 1;
        }
      }

      // Total Posts
      const totalPosts = posts.length;

      // Top Posts
      topPosts.value = posts
        .sort((a, b) => (b.viewCount || 0) - (a.viewCount || 0))
        .slice(0, 5);

      // Fetch Comments and Replies
      let commentsCount = 0;
      let repliesCount = 0;

      for (const post of posts) {
        const commentsRef = collection(db, "Posts", post.id, "Comments");
        const commentsSnapshot = await getDocs(commentsRef);
        commentsCount += commentsSnapshot.size;

        for (const commentDoc of commentsSnapshot.docs) {
          const repliesRef = collection(
            db,
            "Posts",
            post.id,
            "Comments",
            commentDoc.id,
            "Replies"
          );
          const repliesSnapshot = await getDocs(repliesRef);
          repliesCount += repliesSnapshot.size;
        }
      }

      totalComments.value = commentsCount;
      totalReplies.value = repliesCount;

      // Set Metrics
      metrics.value = [
        { title: "Total Posts", value: totalPosts },
        { title: "Total Views", value: totalViews },
        { title: "Total Reads", value: totalReads },
        { title: "Total Comments", value: commentsCount },
      ];

      isLoading.value = false;
    };

    onMounted(() => {
      fetchData();
    });

    return {
      isLoading,
      metrics,
      topPosts,
      totalComments,
      totalReplies,
      postsByLang,
      monthlyPosts,
    };
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  padding: 2rem;
  flex-grow: 1;
}

.card {
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.2rem;
}

.table {
  width: 100%;
}

.canvas-container {
  position: relative;
  height: 300px;
}
</style>
