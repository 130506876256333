<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title || "Confirm" }}</h5>
        <button
          type="button"
          class="close-button"
          @click="$emit('cancel')"
          aria-label="Close"
        >
          &times;
        </button>
      </div>
      <div class="modal-body">
        <p>{{ message || "Are you sure you want to proceed?" }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="$emit('cancel')"
        >
          No
        </button>
        <button type="button" class="btn btn-danger" @click="$emit('confirm')">
          Yes
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "ConfirmationDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: String,
    message: String,
  },
};
</script>
  
  <style scoped>
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.3rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Close Button */
.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
}

/* Modal Body */
.modal-body {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
</style>
  