<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-start">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarNav"
        aria-controls="sidebarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="sidebarNav">
        <ul class="navbar-nav flex-column">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'Dashboard' }"
              active-class="active-tab"
            >
              <i class="bi bi-speedometer2"></i> Dashboard
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'ManagePosts' }"
              active-class="active-tab"
            >
              <i class="bi bi-file-earmark-text"></i> Manage Posts
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'ManageUsers' }"
              active-class="active-tab"
            >
              <i class="bi bi-people"></i> Manage Users
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ name: 'DeletedPosts' }"
              active-class="active-tab"
            >
              <i class="bi bi-trash"></i> Deleted Posts
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
/* Custom styles for sidebar */
.navbar {
  padding: 1rem;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 250px;
  z-index: 1040; /* Ensure the sidebar is above other content */
  height: 100vh; /* Full height */
}

.navbar-nav {
  width: 100%;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  transition: background-color 0.3s ease;
}

.nav-link i {
  margin-right: 8px;
}

/* Active tab styles */
.active-tab {
  background-color: #007bff;
  color: white !important;
  border-radius: 4px;
}

/* Adjust the positioning of the sidebar */
.fixed-start {
  position: fixed;
  top: 0;
  left: 0;
}

.nav-link:hover {
  background-color: #f1f1f1;
}
</style>
