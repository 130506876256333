<template>
  <div class="manage-posts-container">
    <Sidebar />
    <div class="main-content">
      <Navbar />
      <div class="content-wrapper">
        <h2>Deleted Posts</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Author</th>
              <th>Lang</th>
              <th>Deleted At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="post in posts" :key="post.id">
              <td>{{ post.title }}</td>
              <td>{{ post.author }}</td>
              <td>{{ post.lang }}</td>
              <td>{{ formatTimestamp(post.deletedAt) }}</td>
              <td>
                <button
                  disabled
                  class="btn btn-success ml-5"
                  @click="recoverPost(post.id)"
                >
                  Recover
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { collection, getDocs } from "firebase/firestore";
import { db } from "@/firebase/config";

export default {
  components: {
    Navbar,
    Sidebar,
  },
  setup() {
    const store = useStore();
    const posts = ref([]);
    const isLoading = ref(true);

    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, "DeletedPosts"));
      const fetchedPosts = [];
      querySnapshot.forEach((doc) => {
        fetchedPosts.push({ id: doc.id, ...doc.data() });
      });
      posts.value = fetchedPosts.sort((a, b) => b.deletedAt - a.deletedAt);
      isLoading.value = false;
    };

    const formatTimestamp = (timestamp) => {
      if (timestamp && timestamp.seconds) {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleString(); // Adjust to your desired format
      }
      return "N/A";
    };

    const recoverPost = (postId) => {
      store.dispatch("posts/deletePost", postId);
    };

    onMounted(() => {
      fetchPosts();
    });

    return {
      posts,
      recoverPost,
      formatTimestamp,
    };
  },
};
</script>
  
  <style scoped>
.manage-posts-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  padding: 2rem;
  flex-grow: 1;
}

.table {
  width: 100%;
}
</style>
  