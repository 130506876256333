<template>
  <div class="manage-posts-container">
    <Sidebar />
    <div class="main-content">
      <Navbar />
      <div class="content-wrapper">
        <!-- Flexbox for title and button -->
        <div class="header-row">
          <h2>Manage Users</h2>
          <button disabled class="btn btn-primary" @click="addUser">
            Add New User
          </button>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>Display Name</th>
              <th>Email</th>
              <th>First Name</th>
              <th>last Name</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.display_name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.role }}</td>
              <td>
                <button
                  class="btn btn-success"
                  @click="editUser(post.id)"
                  disabled
                >
                  Edit
                </button>
                <span class="mx-2"></span>
                <button
                  class="btn btn-danger"
                  @click="deleteUser(post.id)"
                  disabled
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { collection, getDocs } from "firebase/firestore";
import { db } from "@/firebase/config";

export default {
  components: {
    Navbar,
    Sidebar,
  },
  setup() {
    const store = useStore();
    const users = ref([]);
    const isLoading = ref(true);

    const fecthUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "Users"));
      const fetchedUsers = [];
      querySnapshot.forEach((doc) => {
        fetchedUsers.push({ id: doc.id, ...doc.data() });
      });
      users.value = fetchedUsers;
      isLoading.value = false;
    };

    const addUser = () => {
      // navigate to post creation page
    };

    const editUser = (postId) => {
      // navigate to edit post page
    };

    const deleteUser = (postId) => {
      // delete user logic
    };

    onMounted(() => {
      fecthUsers();
    });

    return {
      users,

      addUser,
      editUser,
      deleteUser,
    };
  },
};
</script>
  
  <style scoped>
.manage-posts-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  padding: 2rem;
  flex-grow: 1;
}

.header-row {
  display: flex;
  justify-content: space-between; /* Aligns the title and button on opposite ends */
  align-items: center;
  margin-bottom: 1rem;
}

.table {
  width: 100%;
}
</style>
  