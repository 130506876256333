import { createRouter, createWebHistory } from 'vue-router';
import { useStore } from 'vuex';

// Import your components
import Login from '@/pages/Login.vue';
import Dashboard from '@/pages/Dashboard.vue';
import ManagePosts from '@/pages/ManagePosts.vue';
import ManageUsers from '@/pages/ManageUsers.vue';
import DeletedPosts from '@/pages/DeletedPosts.vue';
import Profile from '@/pages/Profile.vue';
import EditPost from '@/components/EditPost.vue';
import NewPost from '@/components/NewPost.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,  // This route does not require authentication
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,  // This route requires authentication
    },
  },
  {
    path: '/manage-posts',
    name: 'ManagePosts',
    component: ManagePosts,
    meta: {
      requiresAuth: true,  // This route requires authentication
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,  // This route requires authentication
    },
  },
  {
    path: '/manage-users',
    name: 'ManageUsers',
    component: ManageUsers,
    meta: {
      requiresAuth: true,  // This route requires authentication
    },
  },
  {
    path: '/deleted-posts',
    name: 'DeletedPosts',
    component: DeletedPosts,
    meta: {
      requiresAuth: true,  // This route requires authentication
    },
  },
  {
    path: "/edit-post/:id", // Route with post ID as parameter
    name: "EditPost",
    component: EditPost,
    props: true, // Pass the route params as props to the component
    meta: {
      requiresAuth: true,  // This route requires authentication
    },
  },
  {
    path: "/new-post", // Route for creating a new post
    name: "NewPost",
    component: NewPost,
    meta: {
      requiresAuth: true,  // This route requires authentication
    },
  },

  {
    path: '/:pathMatch(.*)*',  // Catch-all route for 404 Not Found
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Route Guard to check authentication
// Route Guard to check authentication
router.beforeEach((to, from, next) => {
  const store = useStore();  // Access the Vuex store

  const isAuthenticated = store.getters['isAuthenticated']; // Check if user is authenticated

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // If the route requires authentication and the user is authenticated
    if (isAuthenticated) {
      next();  // Proceed to the route
    } else {
      next('/');  // Redirect to the login page if not authenticated
    }
  } else {
    // If user is on the login page and already authenticated, redirect to dashboard
    if (to.path === '/' && isAuthenticated) {
      next('/dashboard');  // Redirect to the dashboard if already logged in
    } else {
      next();  // No authentication required, proceed to the route
    }
  }
});


export default router;
