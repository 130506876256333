import { createStore } from 'vuex'
import { auth, db } from '@/firebase/config';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { watchEffect } from 'vue';

export default createStore({
  state: {
    user: null,
    userProfile: null,
    authIsReady: false
  },
  getters: {
    isAuthenticated: (state) => !!state.user && state.authIsReady,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserProfile(state, payload) {
      state.userProfile = payload
      //console.log('User profile state changed: ', state.userProfile)
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
      //console.log('Auth is ready')
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential) {
        let docRef = doc(db, 'Users', userCredential.user.uid)
        const unsub2 = onSnapshot(docRef, doc => {
          //console.log(doc.data().role)
          if (doc.data()) {
            commit('setUser', userCredential.user)
            commit('setUserProfile', { ...doc.data() })
          } else {
            console.log('Invalid User')
            signOut(auth)
          }
        }, (err) => {
          console.log(err.message)
        })
        watchEffect((onInvalidate) => {
          onInvalidate(() => unsub2()) // unsubscribe from the realtime listener
        })
      } else {
        throw Error('Could not complete login')
      }
    },
    async logout({ commit }) {
      await signOut(auth)
      commit('setUser', null);
    },
    // Action to check authentication state
    checkAuth({ commit }) {
      return new Promise((resolve) => {
        auth.onAuthStateChanged(user => {
          commit('setUser', user);

          if (user) {
            let docRef = doc(db, 'Users', user.uid)
            const unsub2 = onSnapshot(docRef, doc => {
              if (doc.data()) {
                console.log(doc.data())
                commit('setUser', user)
                commit('setUserProfile', { ...doc.data() })
              } else {
                //console.log('Invalid User')
                signOut(auth)
              }
            }, (err) => {
              //console.log(err.message)
            })
            watchEffect((onInvalidate) => {
              onInvalidate(() => unsub2()) // unsubscribe from the realtime listener
            })
          }
          commit('setAuthIsReady', true);
          resolve(user);
        });
      });
    },
  },
  modules: {
  }
})
