<template>
  <div class="new-post-container">
    <Sidebar />
    <div class="main-content">
      <Navbar />
      <div class="content-wrapper">
        <!-- Breadcrumbs with back button -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <span class="p-0" @click="goBack">
                <i class="bi bi-arrow-left"></i>
              </span>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click.prevent="goBack">Manage Posts</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">New Post</li>
          </ol>
        </nav>

        <h2>Create New Post</h2>

        <form @submit.prevent="createPost">
          <!-- Title -->
          <div class="mb-3">
            <label for="title" class="form-label">Title</label>
            <input
              type="text"
              id="title"
              v-model="post.title"
              class="form-control"
              required
            />
          </div>

          <!-- Description -->
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <input
              type="text"
              id="description"
              v-model="post.description"
              class="form-control"
              required
            />
          </div>

          <!-- Author -->
          <div class="mb-3">
            <label for="author" class="form-label">Author</label>
            <input
              type="text"
              id="author"
              v-model="post.author"
              class="form-control"
              required
            />
          </div>

          <!-- Language -->
          <div class="mb-3">
            <label for="lang" class="form-label">Language</label>
            <select id="lang" v-model="post.lang" class="form-select" required>
              <option value="EN">English</option>
              <option value="AR">Arabic</option>
            </select>
          </div>

          <!-- Tags -->
          <div class="mb-3">
            <label for="tags" class="form-label">Tags</label>
            <div class="input-group">
              <input
                type="text"
                id="tags"
                v-model="newTag"
                class="form-control"
                placeholder="Add a tag"
                @keyup.enter="addTag"
              />
              <button class="btn btn-secondary" type="button" @click="addTag">
                Add Tag
              </button>
            </div>
            <div class="mt-2">
              <span
                v-for="tag in post.tags"
                :key="tag"
                class="badge bg-primary me-1"
              >
                {{ tag }}
                <i class="bi bi-x-circle ms-1" @click="removeTag(tag)"></i>
              </span>
            </div>
          </div>

          <!-- Image Upload and Preview -->
          <div class="mb-3">
            <label for="image" class="form-label">Thumbnail Image</label>
            <input
              type="file"
              id="image"
              @change="onImageChange"
              accept="image/*"
              class="form-control"
            />
            <div v-if="imagePreview" class="mt-2">
              <img
                :src="imagePreview"
                alt="Image Preview"
                class="img-thumbnail"
                style="max-width: 200px"
              />
            </div>
          </div>

          <!-- Content -->
          <div class="mb-3">
            <label for="content" class="form-label">Content</label>
            <QuillEditor
              class="form-control"
              v-model:content="post.body"
              content-type="html"
              theme="snow"
            />
          </div>

          <!-- Submit Button -->
          <button type="submit" class="btn btn-primary">Create Post</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "@/firebase/config";
import {
  uploadBytes,
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    Navbar,
    Sidebar,
    QuillEditor,
  },
  setup() {
    const router = useRouter();
    const post = ref({
      title: "",
      description: "",
      author: "",
      lang: "EN", // Default language is English
      body: "",
      tags: [],
      imageUrl: "",
      createdAt: null,
      viewCount: 0,
      readCount: 0,
    });
    const newTag = ref(""); // Tag input field
    const imageFile = ref(null);
    const imagePreview = ref(null);

    // Handle Image Change
    const onImageChange = (event) => {
      imageFile.value = event.target.files[0];
      if (imageFile.value) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result;
        };
        reader.readAsDataURL(imageFile.value);
      }
    };

    // Create Post
    const createPost = async () => {
      try {
        // Upload the image to Firebase Storage if an image is selected
        if (imageFile.value) {
          const fileRef = storageRef(
            storage,
            `blog-images/${post.value.title}_${Date.now()}`
          );
          const snapshot = await uploadBytes(fileRef, imageFile.value);
          const downloadUrl = await getDownloadURL(snapshot.ref);
          post.value.imageUrl = downloadUrl;
        }

        // Set the createdAt timestamp
        post.value.createdAt = serverTimestamp();

        // Add the post to Firestore
        await addDoc(collection(db, "Posts"), post.value);

        // Redirect to manage posts page after creating
        router.push({ name: "ManagePosts" });
      } catch (error) {
        console.error("Error creating post:", error);
        // Handle error, display message to user
        alert("An error occurred while creating the post. Please try again.");
      }
    };

    // Navigation Functions
    const goBack = () => {
      router.push({ name: "ManagePosts" });
    };

    // Tag Functions
    const addTag = () => {
      if (newTag.value && !post.value.tags.includes(newTag.value)) {
        post.value.tags.push(newTag.value); // Add the new tag
        newTag.value = ""; // Clear input
      }
    };

    const removeTag = (tag) => {
      post.value.tags = post.value.tags.filter((t) => t !== tag); // Remove the tag
    };

    return {
      post,
      newTag,
      createPost,
      goBack,
      addTag,
      removeTag,
      onImageChange,
      imagePreview,
    };
  },
};
</script>

<style scoped>
.new-post-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main-content {
  margin-left: 250px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  padding: 2rem;
  flex-grow: 1;
}

/* Styling for Breadcrumbs */
.breadcrumb {
  background: transparent;
  padding: 0;
}

.breadcrumb-item .btn-link {
  font-size: 1rem;
  text-decoration: none;
  color: #007bff;
}

/* Tag styling */
.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.badge .bi-x-circle {
  cursor: pointer;
  margin-left: 0.5rem;
}
</style>
