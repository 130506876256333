<template>
  <div class="edit-post-container">
    <Sidebar />
    <div class="main-content">
      <Navbar />
      <div class="content-wrapper">
        <!-- Breadcrumbs with back button -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <span class="p-0" @click="goBack">
                <i class="bi bi-arrow-left"></i>
              </span>
            </li>
            <li class="breadcrumb-item">
              <a href="#" @click.prevent="goBack">Manage Posts</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit Post
            </li>
          </ol>
        </nav>

        <h2>Edit Post</h2>

        <!-- Loading Spinner -->
        <LoadingSpinner v-if="isLoading" />

        <!-- Edit Form -->
        <form v-else @submit.prevent="confirmUpdate">
          <!-- Title -->
          <div class="mb-3">
            <label for="title" class="form-label">Title</label>
            <input
              type="text"
              id="title"
              v-model="editedPost.title"
              class="form-control"
              required
            />
          </div>

          <!-- Description -->
          <div class="mb-3">
            <label for="description" class="form-label">Description</label>
            <input
              type="text"
              id="description"
              v-model="editedPost.description"
              class="form-control"
              required
            />
          </div>

          <!-- Author -->
          <div class="mb-3">
            <label for="author" class="form-label">Author</label>
            <input
              type="text"
              id="author"
              v-model="editedPost.author"
              class="form-control"
              required
            />
          </div>

          <!-- Language -->
          <div class="mb-3">
            <label for="lang" class="form-label">Language</label>
            <select
              id="lang"
              v-model="editedPost.lang"
              class="form-select"
              required
            >
              <option value="EN">English</option>
              <option value="AR">Arabic</option>
            </select>
          </div>

          <!-- Tags -->
          <div class="mb-3">
            <label for="tags" class="form-label">Tags</label>
            <div class="input-group">
              <input
                type="text"
                id="tags"
                v-model="newTag"
                class="form-control"
                placeholder="Add a tag"
                @keyup.enter="addTag"
              />
              <button class="btn btn-secondary" type="button" @click="addTag">
                Add Tag
              </button>
            </div>
            <div class="mt-2">
              <span
                v-for="tag in editedPost.tags"
                :key="tag"
                class="badge bg-primary me-1"
              >
                {{ tag }}
                <i class="bi bi-x-circle ms-1" @click="removeTag(tag)"></i>
              </span>
            </div>
          </div>

          <!-- Image Upload and Preview -->
          <div class="mb-3">
            <label for="image" class="form-label">Thumbnail Image</label>
            <input
              type="file"
              id="image"
              @change="onImageChange"
              accept="image/*"
              class="form-control"
            />
            <div class="mt-2">
              <img
                :src="imagePreview || editedPost.imageUrl"
                alt="Image Preview"
                class="img-thumbnail"
                style="max-width: 200px"
              />
            </div>
          </div>

          <!-- Content -->
          <div class="mb-3">
            <label for="content" class="form-label">Content</label>
            <QuillEditor
              class="form-control"
              v-model:content="editedPost.body"
              content-type="html"
              theme="snow"
            />
          </div>

          <!-- Update Button -->
          <button type="submit" class="btn btn-primary" :disabled="!isDirty">
            Update Post
          </button>
        </form>
        <!-- Confirmation Dialog -->
        <ConfirmDialog
          :visible="isDialogVisible"
          :title="'Confirm Update'"
          :message="'Are you sure you want to update this post?'"
          @confirm="updatePost"
          @cancel="isDialogVisible = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "@/firebase/config";
import {
  uploadBytes,
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage";
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ConfirmDialog from "@/components/ConfirmationDialog.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    Navbar,
    Sidebar,
    LoadingSpinner,
    ConfirmDialog,
    QuillEditor,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const postId = route.params.id; // Adjust according to your route params
    const isLoading = ref(true);
    const originalPost = ref(null);
    const editedPost = reactive({
      title: "",
      description: "",
      author: "",
      lang: "EN",
      body: "",
      tags: [],
      imageUrl: "",
    });
    const newTag = ref("");
    const imageFile = ref(null);
    const imagePreview = ref(null);
    const isDialogVisible = ref(false);

    const fetchPost = async () => {
      try {
        const postRef = doc(db, "Posts", postId);
        const postSnap = await getDoc(postRef);
        if (postSnap.exists()) {
          const data = postSnap.data();
          // Set original post data
          originalPost.value = { ...data };
          // Set edited post data
          Object.assign(editedPost, data);
          isLoading.value = false;
        } else {
          console.error("Post not found");
          router.push({ name: "ManagePosts" });
        }
      } catch (error) {
        console.error("Error fetching post:", error);
        router.push({ name: "ManagePosts" });
      }
    };

    // Handle Image Change
    const onImageChange = (event) => {
      imageFile.value = event.target.files[0];
      if (imageFile.value) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result;
        };
        reader.readAsDataURL(imageFile.value);
      }
    };

    // Tag Functions
    const addTag = () => {
      if (newTag.value && !editedPost.tags.includes(newTag.value)) {
        editedPost.tags.push(newTag.value);
        newTag.value = "";
      }
    };

    const removeTag = (tag) => {
      editedPost.tags = editedPost.tags.filter((t) => t !== tag);
    };

    // Check if any changes have been made
    const isDirty = computed(() => {
      if (!originalPost.value) return false;
      const fieldsToCheck = [
        "title",
        "description",
        "author",
        "lang",
        "body",
        "tags",
      ];
      for (const field of fieldsToCheck) {
        if (
          JSON.stringify(editedPost[field]) !==
          JSON.stringify(originalPost.value[field])
        ) {
          return true;
        }
      }
      if (imageFile.value) {
        return true;
      }
      return false;
    });

    // Show confirmation dialog before updating
    const confirmUpdate = () => {
      if (isDirty.value) {
        isDialogVisible.value = true;
      }
    };

    // Update Post
    const updatePost = async () => {
      isDialogVisible.value = false;
      isLoading.value = true;
      try {
        const postRef = doc(db, "Posts", postId);
        // Upload new image if selected
        if (imageFile.value) {
          const fileRef = storageRef(
            storage,
            `blog-images/${editedPost.title}_${Date.now()}`
          );
          const snapshot = await uploadBytes(fileRef, imageFile.value);
          const downloadUrl = await getDownloadURL(snapshot.ref);
          editedPost.imageUrl = downloadUrl;
        }

        // Update the post in Firestore
        await updateDoc(postRef, {
          title: editedPost.title,
          description: editedPost.description,
          author: editedPost.author,
          lang: editedPost.lang,
          body: editedPost.body,
          tags: editedPost.tags,
          imageUrl: editedPost.imageUrl,
          // Optionally, you can add a modifiedAt timestamp
          modifiedAt: new Date(),
        });

        // Redirect to manage posts page after updating
        router.push({ name: "ManagePosts" });
      } catch (error) {
        console.error("Error updating post:", error);
        alert("An error occurred while updating the post. Please try again.");
        isLoading.value = false;
      }
    };

    // Navigation Functions
    const goBack = () => {
      router.push({ name: "ManagePosts" });
    };

    onMounted(() => {
      fetchPost();
    });

    return {
      editedPost,
      newTag,
      addTag,
      removeTag,
      onImageChange,
      imagePreview,
      isLoading,
      isDirty,
      confirmUpdate,
      isDialogVisible,
      updatePost,
      goBack,
    };
  },
};
</script>

<style scoped>
.edit-post-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main-content {
  margin-left: 250px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  padding: 2rem;
  flex-grow: 1;
}

/* Styling for Breadcrumbs */
.breadcrumb {
  background: transparent;
  padding: 0;
}

.breadcrumb-item .btn-link {
  font-size: 1rem;
  text-decoration: none;
  color: #007bff;
}

/* Tag styling */
.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.badge .bi-x-circle {
  cursor: pointer;
  margin-left: 0.5rem;
}
</style>
