import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Import Bootstrap Icons
import 'bootstrap-icons/font/bootstrap-icons.css';

// Import Quill Editor
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// Check authentication status before mounting the app
store.dispatch('checkAuth')
    .then(() => {
        const app = createApp(App);

        // Register global components
        app.component('QuillEditor', QuillEditor);

        // Use the router and store
        app.use(store);
        app.use(router);

        // Mount the app
        app.mount('#app');
    })
    .catch((error) => {
        console.error('Failed to check authentication:', error);
    });
