<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <div v-if="currentUser" class="container-fluid">
      <a class="navbar-brand" href="#"><b>The Syrian Blog</b> - Admin Panel</a>
      <div class="ms-auto d-flex align-items-center">
        <!-- Dropdown for the User Profile -->
        <div class="dropdown">
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            id="userDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ currentUser.display_name }}
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="userDropdown"
          >
            <li>
              <!-- Use router-link for navigation -->
              <router-link class="dropdown-item" :to="{ name: 'Profile' }"
                >Profile</router-link
              >
            </li>
            <li>
              <button class="dropdown-item" @click="logout">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.state.userProfile);
    const router = useRouter();

    const logout = async () => {
      await store.dispatch("logout");
      router.push({ name: "Login" });
    };

    return {
      currentUser,
      logout,
    };
  },
};
</script>

<style scoped>
/* Adjust any custom styling if necessary */
.navbar {
  padding: 1rem 1.5rem;
}

.btn-outline-secondary {
  border-color: #6c757d;
}
</style>
