<template>
  <div class="profile-container">
    <Sidebar />
    <div class="main-content">
      <Navbar />
      <div class="content-wrapper">
        <h2>Profile</h2>

        <!-- Profile Info Form -->
        <form @submit.prevent="updateProfile">
          <div class="mb-3">
            <label for="displayName" class="form-label">Display Name</label>
            <input
              type="text"
              id="displayName"
              v-model="profile.display_name"
              class="form-control"
              required
            />
          </div>

          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              id="email"
              v-model="profile.email"
              class="form-control"
              disabled
            />
          </div>

          <div class="mb-3">
            <label for="firstName" class="form-label">First Name</label>
            <input
              type="text"
              id="firstName"
              v-model="profile.first_name"
              class="form-control"
            />
          </div>

          <div class="mb-3">
            <label for="lastName" class="form-label">Last Name</label>
            <input
              type="text"
              id="lastName"
              v-model="profile.last_name"
              class="form-control"
            />
          </div>

          <div class="mb-3">
            <label for="role" class="form-label">Role</label>
            <input
              type="text"
              id="role"
              v-model="profile.role"
              class="form-control"
              disabled
            />
          </div>

          <!-- Profile Photo Section -->
          <div class="mb-3">
            <label for="photo" class="form-label">Profile Photo</label>
            <div class="profile-photo-wrapper">
              <img
                :src="profile.photo_url"
                alt="Profile Photo"
                class="profile-photo"
              />
              <input
                type="file"
                @change="onFileChange"
                accept="image/*"
                class="form-control-file mt-2"
              />
            </div>
          </div>

          <button type="submit" class="btn btn-primary">Update Profile</button>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
// import { updateUserProfile } from "@/firebase"; // Your custom Firebase update function

export default {
  components: {
    Sidebar,
    Navbar,
  },
  setup() {
    const store = useStore();
    const profile = reactive({
      display_name: "",
      email: "",
      first_name: "",
      last_name: "",
      role: "",
      photo_url: "",
    });
    const newPhoto = ref(null);
    const storage = getStorage(); // Firebase Storage

    const loadProfile = () => {
      const user = store.state.userProfile; // Assuming userProfile is stored in Vuex
      profile.display_name = user.display_name;
      profile.email = user.email;
      profile.first_name = user.first_name;
      profile.last_name = user.last_name;
      profile.role = user.role;
      profile.photo_url = user.photo_url;
    };

    const onFileChange = (e) => {
      newPhoto.value = e.target.files[0];
    };

    const updateProfile = async () => {
      if (newPhoto.value) {
        const photoStorageRef = storageRef(
          storage,
          `profile_photos/${profile.email}`
        );
        const snapshot = await uploadBytes(photoStorageRef, newPhoto.value);
        const downloadURL = await getDownloadURL(snapshot.ref);
        profile.photo_url = downloadURL;
      }

      // Call Firebase update function to save the changes
      //   await updateUserProfile(profile);

      // Update the profile in the store (if necessary)
      //   store.dispatch("updateUserProfile", profile);
    };

    loadProfile();

    return {
      profile,
      onFileChange,
      updateProfile,
    };
  },
};
</script>
  
  <style scoped>
.profile-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.main-content {
  margin-left: 250px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  padding: 2rem;
  flex-grow: 1;
}

.profile-photo-wrapper {
  display: flex;
  align-items: center;
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}
</style>
  